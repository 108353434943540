export const BASE_URL = "https://localhost:44356";
//export const BASE_URL = "https://vportaldev.visionet.co.id";




export const CASCADING_DROP_DOWN_IS_INITIALIZED_EVENT_NAME = 'initializedCascading';

// From AppConsts.cs
export const DATA_VALMSG_FOR_ATTRIBUTE_NAME = "data-valmsg-for";
export const DATA_DROP_DOWN_INITIAL_VALUE_ATTRIBUTE_NAME = "data-drop-down-initial-value";
export const DATA_DROP_DOWN_IS_CASCADING_ATTRIBUTE_NAME = "data-drop-down-is-cascading";
export const DATA_CASCADING_DROPDOWN_IS_INITIALIZED = "data-cascading-drop-down-is-initialized";
export const DATA_DROP_DOWN_INITIAL_VALUE_SEPARATOR = "|";
export const MAP_SUFFIX = "Map";
export const LANGUAGE_SUBMIT_BUTTON_CLASS_NAME = "language-submit-btn";
export const INPUT_NAME_TO_VALUE_AND_TEXT_DICTIONARY_MAP = "inputNameToValueAndTextDictionaryMap";

// From others

