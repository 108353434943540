import $ from "jquery";
import * as appConsts from "./appConsts";
import Toastify from 'toastify-js'

export class AppException extends Error {
    constructor(message?: string) {
        super(message);
        //Object.setPrototypeOf(this, new.target.prototype);
        this.name = 'AppException';
    }
}


//=== Alerting ===

const errorAlertId = 'error-alert';
const errorAlertWrapperId = 'error-alert-wrapper';

export function showSuccessToast(message: string) {
    Toastify({
        text: message,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "bottom", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        backgroundColor: 'rgb(0, 106, 218)'
    }).showToast();
}

export function showErrorToast(errorMessage: string) {
    Toastify({
        text: errorMessage,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "bottom", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        backgroundColor: '#dc3545'
    }).showToast();
}

export function displayErrorMessage(errorMessage: string) {
    console.error(errorMessage);
    const errorAlertElem = getElementById(errorAlertId);
    const errorAlertWrapperElem = getElementById(errorAlertWrapperId);

    errorAlertElem.innerText = errorMessage;

    $(errorAlertWrapperElem).removeClass('d-none');
}

export function hideErrorMessage() {
    const errorAlertElem = getElementById(errorAlertId);
    const errorAlertWrapperElem = getElementById(errorAlertWrapperId);

    errorAlertElem.innerText = '';

    $(errorAlertWrapperElem).addClass('d-none');
}

export function checkAnyMatch(searchTerm: string, ...args: (string|undefined)[]) {
    if (!searchTerm) return true;

    for (const item of args) {
        if (item && item.includes(searchTerm)) {
            return true;
        }
    }

    return false;
}


// === URL ===

/**
 * Combine two strings into a single URL.
 * @param url1
 * @param url2
 */
export function joinUrl(url1: string | null | undefined, url2: string | null | undefined): string {
    if (url1 == null && url2 == null) return "";
    if (url1 == null) return url2 || "";
    if (url2 == null) return url1;

    if (!url1.endsWith("/")) {
        url1 += "/";
    }

    let finalUrl = url1 + url2;
    finalUrl = finalUrl.replace("://", ":///");
    finalUrl = finalUrl.replace("//", "/");
    finalUrl = finalUrl.replace(":///", "://");

    return finalUrl;
}


// === HTML ===

/**
 * Creates an element.
 * @param className
 * @param type
 */
export function createElement(className: string, type?: string | null | undefined) {
    if (type == null) type = 'div';
    const elem = document.createElement(type);
    elem.className = className;
    return elem;
}

/**
 * Get an element by ID or throw an exception if missing.
 * @param id
 */
export function getElementById(id: string) {
    let result = document.getElementById(id);
    const alias = 'Form_' + id;

    if (result == null) {
        result = document.getElementById(alias);
    }

    if (result == null) {
        throw new AppException(`Could not find element with id '${id}' or '${alias}'.`);
    }

    return result;
}

export function getElementDate(id: string) {
    const element: HTMLInputElement = <HTMLInputElement>getElementById(id);
    return getIndonesianDateOrNull(element.value)
}

export function getIndonesianDateOrNull(dateString: string): (Date | null) {
    if (dateString == '') {
        return null;
    }

    const parts = dateString.trim().split('/');

    let date = null;

    try {
        date = new Date(parseInt(parts[2], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[0], 10));

        return date;
    } catch (e) {
        let dateMs = Date.parse(dateString);

        if (isNaN(dateMs)) {
            return null;
        }

        return new Date(dateMs);
    }
}

export function getElementValue(id: string) {
    const element: HTMLInputElement = <HTMLInputElement>getElementById(id);
    return element.value;
}

export function getOptionalElementValue(id: string) {
    const element: HTMLInputElement = <HTMLInputElement>document.getElementById(id);
    if (element == null) return null;
    return element.value;
}

export function getElementInt(id: string): number {
    const element: HTMLInputElement = <HTMLInputElement>getElementById(id);
    return parseInt(element.value);
}

export function getElementIntOrNull(id: string): number | null {
    const element: HTMLInputElement = <HTMLInputElement>getElementById(id);
    let result = parseInt(element.value);

    if (isNaN(result)) {
        return null;
    } else {
        return result;
    }

}

export function getElementArray(id: string): string[] {
    const element: HTMLSelectElement = <HTMLSelectElement>getElementById(id);
    return getSelectedValues(element);
}

export function getSelectedValues(elem: HTMLSelectElement) {
    const selectedValues = [];

            for (let i = 0; i < elem.children.length; i++) {
                if (elem.children[i].tagName !== 'OPTION') continue;
                const option = elem.children[i] as HTMLOptionElement;

                if (option.selected) {
                    selectedValues.push(option.value);
                }
    }

    return selectedValues;
}

// === General ===

export function removeElement(items: any[] | null | undefined, elementToRemove: any) {
    if (items == null) return;

    for (let i = 0; i < items.length; i++) {
        if (items[i] === elementToRemove) {
            items.splice(i, 1);
            return;
        }
    }

    return;
}

export function groupBy<T>(items: T[], callbackfn: (value: T) => string): { [key: string]: T[] } {
    var dict: { [key: string]: T[] } = {};

    for (let item of items) {
        let key = callbackfn(item);

        if (dict[key] == null) {
            dict[key] = [];
        }

        dict[key].push(item);
    }

    return dict;
}

export function countKeys(dict: { [key: string]: any }) {
    let result = 0;

    for (const i in dict) {
        result++;
    }

    return result;
}

/**
 * Get the current culture, such as en-US or id-ID.
 */
export function getCurrentCulture() {
    const langAttribute = document.documentElement.getAttribute('lang');

    if (langAttribute == null || langAttribute == '') {
        throw new AppException('Could not get current culture from HTML element because the attribute is missing.');
    }

    return langAttribute;
}

/**
 * Remove all the children of an HTML element.
 * @param element
 */
export function removeChildren(element: HTMLElement) {
    if (element == null) return;

    while (element.lastChild != null) {
        element.removeChild(element.lastChild);
    }
}

export function addChildren(element: HTMLElement, newChildren: HTMLElement[] | null) {
    if (element == null || newChildren == null || newChildren.length === 0) {
        return;
    }

    for (const newChild of newChildren) {
        element.appendChild(newChild);
    }
}


// === Global ===

/**
 * Gets a variable stored in window, making sure to throw an exception if it
 * does not exist.
 * @param globalVariableName
 */
export function getGlobalVariable(globalVariableName: string) {
    let result = (window as any)[globalVariableName];

    if (result === undefined) {
        throw new AppException(`Could not find global variable called '${globalVariableName}'.`);
    }

    return result;
}

export function tryGetGlobalVariable(globalVariableName: string) {
    return (window as any)[globalVariableName];
}

export function tryGetGlobalArrayOrEmptyArray(globalVariableName: string): any[] {
    return (window as any)[globalVariableName] || [];
}

export function tryGetInputNameToValueAndTextDictionary(inputName: string): { [key: string]: string } | null {
    const inputNameToValueAndTextDictionaryMap = (window as any)[appConsts.INPUT_NAME_TO_VALUE_AND_TEXT_DICTIONARY_MAP] as { [key: string]: { [key: string]: string } } | null;

    if (inputNameToValueAndTextDictionaryMap == null) {
        return null;
    }

    return inputNameToValueAndTextDictionaryMap[inputName];
}

export function getGlobalStringDictionary(globalVariableName: string) {
    return getGlobalVariable(globalVariableName) as { [key: string]: string };
}

