import { Grid, html, UserConfig } from "gridjs";
import Row from "gridjs/dist/src/row";
import { OneDArray, TCell, TColumn, TDataObjectRow } from "gridjs/dist/src/types";
import { AppException, getElementById } from "./core";
import { ComponentChild, render } from 'preact';
import { checkIsAllowedToUpdate } from "./formUtilities";


// === Row editing ===

/**
 * Result container for getting data from a grid's `config.data`.
 */
interface IHasIndexAndTDataObjectRow {
    index: number,
    dataObjectRow: TDataObjectRow | null
}

/**
  Add a row to a Grid or update it if it exists. Then refresh the grid.
 * @param dataObjectRow
 * @param grid
 * @param checkIfRowMatches A function to determine if a row is the one to be
 * replaced/updated. If it is null, assume we're adding a new row.
 */
export function addOrUpdateRowInGrid(
    dataObjectRow: TDataObjectRow,
    grid: Grid,
    checkIfRowMatches: (dataObjectRow: TDataObjectRow) => boolean
) {
    if (dataObjectRow == null) return;

    if (grid == null) {
        throw new AppException("Could not add row to grid because the grid is null.");
    }
    if (grid.config == null) {
        throw new AppException("Could not add row to grid because the config is null.");
    }

    if (grid.config.data == null) {
        grid.config.data = [];
    }

    const data = grid.config.data as TDataObjectRow[];

    if (checkIfRowMatches == null) {
        data.push(dataObjectRow);
    } else {
        let existingRowFound = false;

        for (let i = 0; i < grid.config.data.length; i++) {
            const existingDataObjectRow = data[i];

            if (checkIfRowMatches(existingDataObjectRow)) {
                existingRowFound = true;
                data[i] = dataObjectRow;
                break;
            }
        }

        if (!existingRowFound) {
            data.push(dataObjectRow);
        }
    }

    grid.forceRender();
}

/**
 * Add rows to a Grid and refresh the grid.
 * @param dataObjectRows
 * @param grid
 */
export function addRowsToGrid(dataObjectRows: TDataObjectRow[], grid: Grid) {
    if (dataObjectRows == null || dataObjectRows.length === 0) {
        return;
    }

    if (grid == null) {
        throw new AppException("Could not add rows to grid because the grid is null.");
    }
    if (grid.config == null) {
        throw new AppException("Could not add rows to grid because the config is null.");
    }

    if (grid.config.data == null) {
        grid.config.data = [];
    }

    for (let i = 0; i < dataObjectRows.length; i++) {
        const row = dataObjectRows[i];
        (grid.config.data as TDataObjectRow[]).push(row);
    }

    grid.forceRender();
}

/**
 * Delete a given row.
 * @param row
 * @param grid
 * @param checkIfRowMatches
 */
export function deleteRowInGrid(
    row: Row,
    grid: Grid,
    checkIfRowMatches?: ((dataObjectRow: TDataObjectRow, visibleGridRowAsObject: any) => boolean) | undefined | null
) {
    if (grid == null) {
        throw new AppException("Could not delete row in grid because the grid is null.");
    }

    if (grid.config == null || grid.config.data == null) {
        return;
    }

    const columns = (<UserConfig>grid.config).columns;

    if (columns == null) {
        throw new AppException(`Could not get original data rows for grid '${grid.config.container?.id}' because it has no columns.`);
    }

    const visibleGridRowAsObject = createObjectFromGridRow(columns, row);

    const dataArray = grid.config.data as TDataObjectRow[];

    checkIfRowMatches = checkIfRowMatches || defaultCheckIfRowMatches;

    for (let i = 0; i < grid.config.data.length; i++) {
        if (checkIfRowMatches(dataArray[i], visibleGridRowAsObject)) {
            (grid.config.data as TDataObjectRow[]).splice(i, 1);
            break;
        }
    }

    grid.forceRender();
}


/**
 * Given a visible grid row, try get the underlying `dataObjectRows` in
 * `config.data`.
 * @param row
 * @param grid
 */
export function getDataObjectRow(
    row: Row,
    grid: Grid,
    checkIfRowMatches?: ((configDataElement: TDataObjectRow, visibleGridRowAsObject: any) => boolean) | undefined | null
): IHasIndexAndTDataObjectRow {
    const rows = getDataObjectRowsCore(
        row,
        grid,
        true,
        checkIfRowMatches
    );

    return rows[0];
}

/**
 * Given a gridjs row (an array of preact cells), find the corresponding
 * elements in `grid.config.data`.
 * @param row
 * @param grid
 */
export function getDataObjectRows(
    row: Row,
    grid: Grid,
    checkIfRowMatches?: ((configDataElement: TDataObjectRow, visibleGridRowAsObject: any) => boolean) | undefined | null
): IHasIndexAndTDataObjectRow[] {
    return getDataObjectRowsCore(
        row,
        grid,
        false,
        checkIfRowMatches
    );
}

/**
 * The core function for searching with a visible grid row and getting the
 * underlying `dataObjectRows` in `config.data`.
 * @param row
 * @param grid
 * @param shouldOnlyGetFirstResult
 * @param checkIfRowMatches
 */
function getDataObjectRowsCore(
    row: Row,
    grid: Grid,
    shouldOnlyGetFirstResult: boolean,
    checkIfRowMatches?: ((configDataElement: TDataObjectRow, visibleGridRowAsObject: any) => boolean) | undefined | null
): IHasIndexAndTDataObjectRow[] {
    const columns = (<UserConfig>grid.config).columns;

    if (columns == null) {
        throw new AppException(`Could not get original data rows for grid '${grid.config.container?.id}' because it has no columns.`);
    }

    const configData = grid.config.data;
    const defaultEmptyResult: IHasIndexAndTDataObjectRow[] = [{
        index: -1,
        dataObjectRow: null
    }];

    if (configData == null || configData.length === 0) {
        return defaultEmptyResult;
    }

    if (!Array.isArray(configData)) {
        throw new AppException(`Error occured in grid with id '${grid.config.container?.id}'. Currently does not support getting rows if 'config.data' is not an array.`);
    }

    checkIfRowMatches = checkIfRowMatches || defaultCheckIfRowMatches;

    const visibleGridRowAsObject = createObjectFromGridRow(columns, row);
    const results: IHasIndexAndTDataObjectRow[] = [];

    for (let i = 0; i < configData.length; i++) {
        const dataObjectRow = configData[i];

        if (Array.isArray(dataObjectRow)) {
            throw new AppException(`Error occured in grid with id '${grid.config.container?.id}'. Currently does not support getting rows when a row is an array.`);
        }

        if (checkIfRowMatches(dataObjectRow, visibleGridRowAsObject)) {
            if (!shouldOnlyGetFirstResult) {
                results.push({
                    index: i,
                    dataObjectRow: dataObjectRow
                });
            } else {
                return [{
                    index: i,
                    dataObjectRow: dataObjectRow
                }];
            }
        }
    }

    if (results.length === 0) {
        return defaultEmptyResult;
    } else {
        return results;
    }
}

/**
 * Create an object by reading a grid's columns and a specific row in that grid
 * (where the row is the data used in rendering and not the one stored in
 * `config.data`).
 * @param columns
 * @param row
 */
function createObjectFromGridRow(
    columns: OneDArray<TColumn | string | ComponentChild>,
    row: Row
) {
    const columnIds: (string | undefined)[] = columns.map(x => (<TColumn>x).id);

    const visibleGridRowAsObject: { [key: string]: any } = {};

    for (let i = 0; i < columnIds.length; i++) {
        const columnId = columnIds[i];

        if (columnId == null) {
            throw new AppException(`Could not create object from grid row because the ${i}th column in the grid has a null id.`);
        }

        if (columnId.endsWith('Button')) {
            continue;
        }

        visibleGridRowAsObject[columnId] = row.cells[i].data;
    }

    return visibleGridRowAsObject;
}

/**
 * The default function to check if a row in a grid's `config.data` matches
 * another JSON.
 * @param configDataElement
 * @param visibleGridRowAsObject
 */
function defaultCheckIfRowMatches(
    configDataElement: TDataObjectRow,
    visibleGridRowAsObject: { [key: string]: any }
): boolean {
    let allColumnsMatch = true;

    for (const key in visibleGridRowAsObject) {
        if (configDataElement[key] !== visibleGridRowAsObject[key]) {
            allColumnsMatch = false;
            break;
        }
    }

    return allColumnsMatch;
}


// === Pagination ===

/**
 * The parameters for pagination.
 */
export interface IPaginationArguments {
    maxResultCount: number
    skipCount: number
}

/**
 * The property name used to store and pass what page a grid is on.
 */
export const pageFormDataName = "page";

/**
 * The property name used to store and pass how many rows to get.
 */
export const limitFormDataName = "limit";

/**
 * Save the current page and limit in a FormData. The data is passed on to
 * another function for pagination.
 *
 * This is a workaround to gridjs' options for pagination.
 * @param page
 * @param limit
 */
export function createBodyWithPageAndLimit(page: number, limit: number): BodyInit {
    const formData = new FormData();

    formData.set(pageFormDataName, page.toString());
    formData.set(limitFormDataName, limit.toString());

    return formData;
}

/**
 * Get the arguments for pagination passed on in the body from a previous
 * function.
 * @param body
 */
export function getPaginationArguments(body: BodyInit | null | undefined): IPaginationArguments {
    if (body == null) {
        throw new AppException("Could not load page and limit because the body is null.");
    }

    if (body instanceof FormData) {
        const pageString = <string>body.get(pageFormDataName);
        const limitString = <string>body.get(limitFormDataName)!;

        const page = parseInt(pageString);
        const limit = parseInt(limitString);
        const skipCount = page * limit;

        return {
            maxResultCount: limit,
            skipCount: skipCount,
        };
    } else {
        throw new AppException("Could not load page and limit because the body is not a FormData object.");
    }
}


//=== Row Formatters ===

export function createDateSpan(value: TCell) {
    let text = '';

    if (value != null) {
        const date = new Date(value as string);
        text = date.toLocaleDateString('en-GB');
    }

    return html(`<span>${text}</span>`);
}

export function createCurrencySpan(value: TCell) {
    let text = '';

    if (value != null) {
        const num = Number(value);

        if (!isNaN(num)) {
            text = num.toLocaleString('id-ID');
        }
    }

    return html(`<span>${text}</span>`);
}

export function createUpdateButton(value: TCell) {
    return createUpdateButtonCore(value, 'Update', 'Update');
}

export function createViewButton(value: TCell) {
    return createUpdateButtonCore(value, 'Read', 'View');
}

/**
 * Create an edit button on a grid. It will look for a button called
 * 'new-button', get the URL, and modify it to become the URL for editing a
 * row.
 * @param value
 */
export function createUpdateButtonCore(value: TCell, target: string, label: string) {
    if (value != null) {
        const newButtonId = 'new-button';
        const newButtonElem = document.getElementById(newButtonId) as HTMLAnchorElement;

        if (!newButtonElem) {
            console.error(`Could not create update button because no anchor element with id '${newButtonId}' exists.`);
            return html(``);
        }

        const isAllowedToUpdateInput = document.getElementById('is-allowed-to-update-input') as HTMLInputElement;

        if (target === 'Update' && isAllowedToUpdateInput != null && isAllowedToUpdateInput.value !== 'true') {
            return html(``);
        }

        let link = '';

        link = newButtonElem.href.replace('/Create', `/${target}`) + '/' + value;

        return html(`<a target="_blank" class="btn btn-sm btn-primary" href="${link}">${label}</a>`);
    } else {
        return html(``);
    }
}

export function createUpdateColumn(columnId?: string | null) {
    return {
        id: columnId ?? 'id',
        name: '',
        formatter: createUpdateButton,
        hidden: () => {
            return !checkIsAllowedToUpdate()
        }
    };
}

export function createViewColumn(columnId?: string | null) {
    return {
        id: columnId ?? 'id',
        name: '',
        formatter: createViewButton,
    };
}

export function createViewColumnWithLink() {
    return {
        id: 'link',
        name: '',
        formatter: (link: string) => {
            return html(`<a class= "btn btn-sm btn-primary" target = "_blank" href="${link}">View</a>`)
        }
    };
}
