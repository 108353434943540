import * as jQueryValidation from "./jQueryValidation";
import "bootstrap-datepicker";
import $ from "jquery";
import "selectize";
import { addChildren, AppException, getCurrentCulture, getElementById, getSelectedValues, removeChildren, tryGetInputNameToValueAndTextDictionary } from "./core";
import Globalize, { DateFormatterOptions } from "globalize/dist/globalize";
import * as appConsts from "./appConsts";

const displayTextSuffix = 'DisplayText';
const currentCulture = getCurrentCulture();

// TODO: The code to configure the CLDR content has not been added because the package was 
// having difficulty being installed. Implement it later. Read more about it here
// https://github.com/globalizejs/globalize

// === Globalization

//Globalize.locale(currentCulture);

const dateFormatterParserRules: DateFormatterOptions = {
    skeleton: 'yMd'
};

const dateFormatter: any = null;//Globalize.dateFormatter(dateFormatterParserRules);
const dateParser: any = null;//Globalize.dateParser(dateFormatterParserRules);


//=== Form Activation ===

export function activateForms() {
    activateFormsCore(true);
}

export function activateSearchForm() {
    activateFormsCore(false);
}

export function activateFormsCore(includeValidation: boolean) {
    $('.bs-datepicker').datepicker({
        format: 'dd/mm/yyyy',
    });

    // TODO: Find good library for time picker
    //$('.bs-datetimepicker').datepicker({
    //    format: 'dd/mm/yyyy',
    //});

    const $selectElems = $('select');

    for (let i = 0; i < $selectElems.length; i++) {
        const $selectElem = $($selectElems[i]);
        let isDisabled = $selectElem.attr('disabled') === 'disabled' || $selectElem.closest('fieldset').attr('disabled') === 'disabled';
        let isReadonly = $selectElem.attr('readonly') === 'readonly' || $selectElem.closest('fieldset').attr('readonly') === 'readonly';

        if (!isReadonly && !isDisabled) {
            $selectElem.removeClass('form-control').selectize({});
        }
    }

    if (includeValidation) {
        jQueryValidation.activateUnobtrusiveValidation();
    }
}

// === Base ===

/**
 * Get the text of the currently selected item in a select list.
 * @param selectElement
 */
function getSelectedTextFromSelectElement(selectElement: HTMLSelectElement): string | null {
    if (selectElement == null) return null;
    if (selectElement.selectedIndex === -1) return null;

    return selectElement.options[selectElement.selectedIndex].text;
}

/**
 * Given an HTML element, find the closest label and return its text content.
 * @param htmlElement
 */
function getLabelText(htmlElement: HTMLElement): string | null {
    if (htmlElement == null) return null;

    let labelElement = document.querySelector(`[for=${htmlElement.id}]`)
    if (labelElement != null) {
        return labelElement.textContent;
    }

    labelElement = getClosestChildInParent(htmlElement, 'label', 3);
    if (labelElement != null) {
        return labelElement.textContent;
    }

    return null;
}

/**
 * Given an HTMLElement, find either its label text or the text of its selected
 * option.
 * @param htmlElement
 */
function getDisplayTextFromHTMLElement(htmlElement: HTMLElement): string | null {
    if (htmlElement == null) return null;
    if (htmlElement.tagName === 'SELECT') {
        return getSelectedTextFromSelectElement(htmlElement as HTMLSelectElement);
    } else {
        return getLabelText(htmlElement);
    }
}

/**
 * Search through the parents and have it run a query selector.
 * @param htmlElement
 * @param querySelector
 * @param limit
 */
function getClosestChildInParent(htmlElement: HTMLElement, querySelector: string, limit: number): HTMLElement | null {
    let currentHTMLElement: HTMLElement | null = htmlElement;

    for (let i = 0; i < limit; i++) {
        if (currentHTMLElement == null) {
            break;
        }

        const targetElement = currentHTMLElement.querySelector(querySelector) as HTMLElement;
        if (targetElement != null) {
            return targetElement;
        }

        currentHTMLElement = currentHTMLElement.parentElement;
    }

    return null;
}

/**
 * Get a form element and convert its contents into a JSON.
 * @param idOrElem
 */
export function createObjectFromForm(idOrElem: string | HTMLElement): any {
    let htmlElem: HTMLElement;

    if (typeof idOrElem === 'string') {
        if (idOrElem.startsWith('#')) {
            idOrElem = idOrElem.substr(1, idOrElem.length - 1);
        }
        htmlElem = getElementById(idOrElem);
    } else {
        htmlElem = idOrElem;
    }

    if (htmlElem.tagName !== 'FORM') {
        throw new AppException(`Could not create object from form using element '${htmlElem.id}' because it is not a form element.`);
    }

    let result: any = {};

    const inputAndSelectElements: NodeListOf<HTMLInputElement | HTMLSelectElement> = htmlElem.querySelectorAll('input, select');

    inputAndSelectElements.forEach((elem: HTMLInputElement | HTMLSelectElement) => {
        insertHTMLInputValueToJSON(elem, result);
    });

    return result;
}

/**
 * Given an HTMLInputElement, determine its value and append it to a JSON.
 * @param elem
 * @param json
 * @param name If provided, will be the name used to determine the key
 * used for the HTML input's value.
 */
export function insertHTMLInputValueToJSON(
    elem: HTMLInputElement | HTMLSelectElement,
    json: { [key: string]: any },
    name?: string | null | undefined,
    shouldIncludeLabel?: boolean | undefined
) {
    if (elem == null) return;
    if (json == null) return;

    if (name == null) {
        name = elem.name;
    }

    let isCheckboxForStringArray = false;

    if (elem.tagName === 'INPUT') {
        elem = elem as HTMLInputElement;
        if (checkIfCheckboxForStringArray(elem)) {

            if (json[name] == null) {
                json[name] = [];
            }

            if (elem.checked) {
                json[name].push(elem.value);
            }

            isCheckboxForStringArray = true;

        } else if (checkIfCheckboxForBoolean(elem)) {
            json[name] = elem.checked;
        } else {
            json[name] = elem.value;
        }
    } else if (elem.tagName === 'SELECT') {
        elem = elem as HTMLSelectElement;
        if (elem.multiple) {
            json[name] = getSelectedValues(elem);
        } else {
            json[name] = elem.value;
        }
    } else {
        json[name] = elem.value;
    }

    if (shouldIncludeLabel && checkIfFormElementHasDisplayText(elem)) {
        const nameForDisplayText = createNameForDisplayText(name);

        if (isCheckboxForStringArray) {
            if (json[nameForDisplayText] == null) {
                json[nameForDisplayText] = [];
            }

            if ((elem as HTMLInputElement).checked) {
                json[nameForDisplayText].push(getDisplayTextFromHTMLElement(elem));
            }
        } else {
            json[nameForDisplayText] = getDisplayTextFromHTMLElement(elem);
        }
    }
}


/**
 * Some form inputs have values that aren't the same as their display text
 * (e.g. a checkbox that has a value of JAK but has a display text of Jakarta).
 * This checks if an element is such a thing.
 */
export function checkIfFormElementHasDisplayText(element: HTMLElement): boolean {
    if (element == null) return false;

    if (element.tagName === 'SELECT') {
        return true;
    }

    if (element.tagName === 'INPUT') {
        if ((element as HTMLInputElement).type === 'checkbox') {
            return true;
        }
    }

    return false;
}

/**
 * Create the name of the property that will contain another property's display
 * text.
 * @param name
 */
export function createNameForDisplayText(name: string) {
    return `${name}${displayTextSuffix}`;
}

/**
 * Given the name of an input/grid column (e.g. areaCode) and a value e.g.
 * (JAKART), look through the global window object for a JS map that contains
 * that value mapped to a string for what it represents (e.g. { JAKART: Jakarta
 * }).
 * @param inputName
 * @param value
 */
export function getDisplayTextFromWindow(inputName: string, value: any) {
    const valueAndTextDictionary = tryGetInputNameToValueAndTextDictionary(inputName);

    if (valueAndTextDictionary != null && typeof (valueAndTextDictionary) === 'object') {
        let result: string[] | string | null;

        if (Array.isArray(value)) {
            const textDisplays: string[] = [];

            for (let subValue of value) {
                textDisplays.push(getDisplayTextOrDefault(subValue, valueAndTextDictionary));
            }

            return convertObjectToDisplayableString(textDisplays);
        } else {
            const text = valueAndTextDictionary[value];

            return convertObjectToDisplayableString(text);
        }
    }

    return null;
}

/**
 * Given a dictionary of values and texts, either get a matching text, or
 * return the value as is if no matching text is found.
 * @param value
 * @param map
 */
function getDisplayTextOrDefault(value: string, map: { [key: string]: string }) {
    const textDisplay = map[value];

    if (textDisplay != null) {
        return textDisplay;
    }

    return value;
}

/**
 * Given the name of an input/grid column (e.g. areaCode), look through an
 * object for a property with a display-like name (e.g. areaCodeDisplayText)
 * and get the value of that.
 * }).
 * @param inputName
 * @param obj
 */
export function getDisplayTextFromObject(inputName: string, obj: { [key: string]: any }): string | null {
    const displayTextStringOrArray = obj[createNameForDisplayText(inputName)];
    return convertObjectToDisplayableString(displayTextStringOrArray);
}

/**
 * Given an object, determine how to display it as a string.
 * @param displayTextStringOrArray
 */
export function convertObjectToDisplayableString(displayTextStringOrArray: string | string[] | null): string | null {
    if (displayTextStringOrArray == null) {
        return null
    } else if (Array.isArray(displayTextStringOrArray)) {
        const displayTextArray = displayTextStringOrArray as string[];
        return displayTextArray.join(', ');
    } else {
        return displayTextStringOrArray;
    }
}

/**
 * Given an HTMLInputElement, update its value according to the value of a JSON.
 * @param elem
 * @param json
 * @param nameInJson By default, an input with name 'foo' will look for a
 * property called 'foo'. Providing an argument for this would allow you to
 * override that default.
 */
export function setHTMLInputValueWithJSON(
    elem: HTMLInputElement | HTMLSelectElement,
    json: { [key: string]: any },
    nameInJson?: string | null
) {
    if (elem == null) return;

    if (nameInJson == null) {
        nameInJson = elem.name;
    }

    function setElemValueDefault(elem: HTMLInputElement | HTMLSelectElement, json: { [key: string]: any }, name: string) {
        if (json == null || json[name] === undefined) {
            elem.value = '';
        } else {
            elem.value = json[name];
        }
    }

    if (elem.tagName === 'INPUT') {
        elem = (elem as HTMLInputElement);

        if (checkIfCheckboxForStringArray(elem)) {
            if (json == null) {
                elem.checked = false;
            } else if (json[nameInJson] == null || !Array.isArray(json[nameInJson])) {
                elem.checked = false
            } else {
                const array = json[nameInJson] as any[];
                elem.checked = array.find(val => val != null && val.toString() === elem.value) != null;
            }
        } else if (checkIfCheckboxForBoolean(elem)) {
            if (json == null) {
                elem.checked = false;
            } else {
                elem.checked = json[nameInJson] === true;
            }
        } else {
            setElemValueDefault(elem, json, nameInJson);
        }
    } else if (elem.tagName === 'SELECT') {
        elem = (elem as HTMLSelectElement);

        if (elem.selectize != null) {
            elem.selectize.clear();

            if (Array.isArray(json[nameInJson])) {
                for (const optionValue of json[nameInJson]) {
                    elem.selectize.addItem(optionValue);
                }
            } else if (json[nameInJson] != null) {
                elem.selectize.addItem(json[nameInJson]);
            } else {
                elem.selectize.clear();
            }
        } else {
            if (Array.isArray(json[nameInJson])) {
                selectOptionsUsingArray(elem, json[nameInJson]);
            } else if (json[nameInJson] != null) {
                elem.value = json[nameInJson];
            } else {
                selectOptionsUsingArray(elem, null);
            }
        }

        if (json != null) {
        } else {
        }
    } else {
        setElemValueDefault(elem, json, nameInJson);
    }
}

function selectOptionsUsingArray(elem: HTMLSelectElement, values: any[] | null | undefined) {
    for (let i = 0; i < elem.children.length; i++) {
        if (elem.children[i].tagName !== 'OPTION') continue;
        const option = elem.children[i] as HTMLOptionElement;

        if (values != null) {
            let isOptionValueFound = false;

            for (let j = 0; j < values.length; j++) {
                if (values[j] === option.value) {
                    isOptionValueFound = true;
                    break;
                }
            }

            option.selected = isOptionValueFound;
        } else {
            option.selected = false;
        }
    }
}

/**
 * Check if a given checkbox input corresponds to a string array.
 * @param elem
 */
function checkIfCheckboxForStringArray(elem: HTMLInputElement) {
    if (elem == null) {
        throw new AppException("Could not determine if input is checkbox for string array because the input is null.");
    }

    return elem.type === 'checkbox' && elem.value !== 'true';
}

/**
 * Check if a given checkbox input corresponds to a boolean.
 * @param elem
 */
function checkIfCheckboxForBoolean(elem: HTMLInputElement) {
    if (elem == null) {
        throw new AppException("Could not determine if input is checkbox for boolean because the input is null.");
    }

    return elem.type === 'checkbox' && elem.value === 'true';
}

/**
 * Try parse a date string, first assuming it is an ISO string, then assuming
 * it is some other format.
 * @param dateString
 */
function parseDateString(dateString: string): Date | null {
    if (dateString == null) {
        return null;
    }

    let date = new Date(dateString);

    if (!isNaN(date.getTime())) {
        return date;
    }

    return dateParser(dateString);
}

/**
 * Format a Date according to the locale.
 * @param date
 */
function formatDateToLocale(date: Date): string {
    const result = dateFormatter(date);

    if (result == null) {
        return '';
    } else {
        return result;
    }
}


// === Validation ===
//
// https://www.hanselman.com/blog/globalization-internationalization-and-localization-in-aspnet-mvc-3-javascript-and-jquery-part-1

/**
 * Validates an input that should be a number with a decimal separator where
 * the decimal separator depends on the current culture.
 * @param value
 * @param element
 */
function validateLocalizedFloatOrDecimal(value: string, element: HTMLInputElement) {
    if (value == null || value.trim() === '') {
        return true;
    }

    const parseResult = Globalize.parseNumber(value);
    return parseResult != null && !isNaN(parseResult);
}

/**
 * Validates an input that should be a date where the format depends on the
 * current culture.
 * @param value
 * @param element
 */
function validateLocalizedDateTime(value: string, element: HTMLInputElement) {
    if (value == null || value.trim() === '') {
        return true;
    }

    const parseResult = dateParser(value);
    return parseResult != null;
}


// Initialization

/**
 * Set the functions that validate inputs.
 */
function setValidators() {
    $.validator.methods['number'] = validateLocalizedFloatOrDecimal;
    $.validator.methods['datetime'] = validateLocalizedDateTime;
}

/**
 * Convert date related input HTML elements into datepickers.
 */
function initializeDatePickers() {
    const datepickerOptions: DatepickerOptions = {
        format: {
            toDisplay: function (date: any, format: string, language: string) {
                return dateFormatter(date);
            },
            toValue: function (date: any, format: string, language: string) {
                return dateParser(date);
            }
        },
        language: currentCulture,
    };

    // Turn each datepicker element into a hidden, and create a text input that
    // will be converted into a bootstrap datepicker. When the text input
    // changes, update the hidden input, giving it the date in the ISO 8601
    // format.

    const $bsDatepickers = $('.bs-datepicker');

    $bsDatepickers.each((i, el: HTMLElement) => {
        const submittedInputElement = <HTMLInputElement>el;

        let displayInputElement = document.createElement('input');
        displayInputElement.type = 'text';
        displayInputElement.className = submittedInputElement.className;
        submittedInputElement.type = 'hidden';
        submittedInputElement.className = '';

        submittedInputElement.parentElement?.appendChild(displayInputElement);

        const $displayInput = $(displayInputElement);

        const setSubmittedInputElement = () => {
            const newDate = parseDateString(displayInputElement.value);

            if (newDate != null) {
                submittedInputElement.value = newDate.toISOString();
            } else {
                submittedInputElement.value = '';
            }
        };

        const setDisplayInputElement = () => {
            const newDate = parseDateString(submittedInputElement.value);

            if (newDate != null) {
                displayInputElement.value = formatDateToLocale(newDate);
                $displayInput.datepicker('update', newDate);
            } else {
                displayInputElement.value = '';
                $displayInput.datepicker('update', '');
            }
        };

        displayInputElement.onblur = setSubmittedInputElement;
        submittedInputElement.onchange = setDisplayInputElement;

        const $datepicker = $displayInput.datepicker(datepickerOptions)

        setDisplayInputElement();
    });
}


// === Cascading Dropdowns ===

export interface ICascadingDropDownChangeContext {
    currentParentValue: string;
}

type CreateOptionElementsFunc = (parentValue: string) => Promise<any>;

export class CascadingDropDown {

    private currentSelectElement: HTMLSelectElement;
    private parentSelectElement: HTMLSelectElement;
    private parentIsCascadingDropDown: boolean;
    private hasBeenInitialized: boolean;
    private createOptionElements: CreateOptionElementsFunc;

    private cachedResults: { [key: string]: HTMLOptionElement[] };

    constructor(
        currentSelectElement: HTMLElement,
        parentSelectElement: HTMLElement,
        createOptionElements: CreateOptionElementsFunc
    ) {
        if (!currentSelectElement) {
            throw new AppException(`Could not initialize cascading dropdown because no current select element was given.`);
        }
        if (currentSelectElement.tagName !== 'SELECT') {
            throw new AppException(`Could not initialize cascading dropdown because the current element with id '${currentSelectElement.id}' is not a SELECT element.`);
        }

        if (!parentSelectElement) {
            throw new AppException(`Could not initialize cascading dropdown because no parent select element was given.`);
        }

        if (parentSelectElement.tagName !== 'SELECT') {
            throw new AppException(`Could not initialize cascading dropdown because the parent element with id '${parentSelectElement.id}' is not a SELECT element.`);
        }

        this.currentSelectElement = currentSelectElement as HTMLSelectElement;
        this.parentSelectElement = parentSelectElement as HTMLSelectElement;
        this.createOptionElements = createOptionElements;
        this.hasBeenInitialized = false;
        this.cachedResults = {};

        this.parentIsCascadingDropDown = this.checkIfParentIsCascadingDropDown();

        this.bindChangeListenerToPossibleCascadingParentAndInitialize();
    }

    /**
     * Check if this cascading drop-down is the child of another cascading
     * drop-down.
     */
    private checkIfParentIsCascadingDropDown(): boolean {
        const attribute = this.parentSelectElement.attributes.getNamedItem(appConsts.DATA_DROP_DOWN_IS_CASCADING_ATTRIBUTE_NAME);

        if (attribute == null) return false;
        return attribute.value.toLowerCase().trim() === 'true';
    }

    /**
     * Read a data attribute that contains what this select element's initial
     * value should be once it and its parent is initialized.
     */
    private getInitialValuesFromDataAttribute() {
        const dataDropDownInitialValueAttribute = this.currentSelectElement.attributes.getNamedItem(appConsts.DATA_DROP_DOWN_INITIAL_VALUE_ATTRIBUTE_NAME);

        if (dataDropDownInitialValueAttribute == null || !dataDropDownInitialValueAttribute.value) {
            return;
        }

        return dataDropDownInitialValueAttribute.value.split(appConsts.DATA_DROP_DOWN_INITIAL_VALUE_SEPARATOR);
    }

    /**
     * Update the current select element, setting what select options are
     * available and selecting any that are applicable.
     */
    private async updateCurrentSelectElement(selectedValues?: string[] | null | undefined) {
        const newOptionElements = await this.getCachedOrNewOptionElements(this.parentSelectElement.value);

        this.setOptionsAndDisability(newOptionElements);

        if (selectedValues != null && selectedValues.length > 0) {
            setSelectElementValues(this.currentSelectElement, selectedValues);
        }

        this.currentSelectElement.dispatchEvent(new Event('change'));
    }

    /**
     * Set the option elements of the current select element and whether to
     * enable the element.
     * @param optionElements
     */
    private setOptionsAndDisability(optionElements: HTMLOptionElement[] | null) {
        removeChildren(this.currentSelectElement);

        addChildren(this.currentSelectElement, optionElements);

        const optionsEmpty = optionElements == null || optionElements.length === 0;

        if (optionsEmpty) {
            this.currentSelectElement.disabled = true;
        } else {
            this.currentSelectElement.disabled = false;
        }
    }

    /**
     * Either get the option elements associated with a parent value from cache
     * or from a service.
     * @param parentValue
     */
    private async getCachedOrNewOptionElements(parentValue: string): Promise<HTMLOptionElement[]> {
        if (parentValue == null || parentValue.trim() === '') {
            return [];
        }

        const cachedResults = this.cachedResults[parentValue];

        if (cachedResults != null) {
            return cachedResults;
        }

        const optionElements = await this.createOptionElements(parentValue).then(result => {
            if (!result || !result.items) {
                return [];
            }

            let optionElements = [
                document.createElement('option')
            ];

            optionElements = optionElements.concat(result.items.map((x: any) => {
                var optionElement = document.createElement("option");
                optionElement.text = x.text || '';
                optionElement.value = x.value || '';
                return optionElement;
            }));

            return optionElements;
        });

        this.cachedResults[parentValue] = optionElements;

        return optionElements;
    }

    /**
     * Bind the change listeners to the parent. However, if the parent is a
     * cascading dropdown, you must wait for it to initialize before you
     * initialize this one.
     */
    private async bindChangeListenerToPossibleCascadingParentAndInitialize() {
        if (this.parentIsCascadingDropDown) {
            this.parentSelectElement.addEventListener(appConsts.CASCADING_DROP_DOWN_IS_INITIALIZED_EVENT_NAME, async e => {
                await this.bindChangeListenerToParentAndInitialize();
                this.currentSelectElement.dispatchEvent(new Event(appConsts.CASCADING_DROP_DOWN_IS_INITIALIZED_EVENT_NAME));
            });
        } else {
            await this.bindChangeListenerToParentAndInitialize();
            this.currentSelectElement.dispatchEvent(new Event(appConsts.CASCADING_DROP_DOWN_IS_INITIALIZED_EVENT_NAME));
        }
    }

    /**
     * Bind the change listeners to the parent.
     */
    private async bindChangeListenerToParentAndInitialize() {
        await this.updateCurrentSelectElement(this.getInitialValuesFromDataAttribute());
        this.parentSelectElement.addEventListener('change', async () => {
            await this.updateCurrentSelectElement();
        });
    }
}

/**
 * Given a select element and an array of string, go through each option and
 * set any matches as selected.
 * @param selectElement
 * @param values
 */
function setSelectElementValues(selectElement: HTMLSelectElement, values: string[]) {
    const optionElements = Array.from(selectElement.querySelectorAll('option'));

    for (let optionElement of optionElements) {
        optionElement.selected = false;
    }

    for (let optionElement of optionElements) {
        if (values.includes(optionElement.value)) {
            optionElement.selected = true;
        }
    }
}

//=== Misc ===

export function checkIsSelfService() {
    const elem = document.getElementById('IsSelfService') as HTMLInputElement;

    if (elem == null) return false;

    return elem.value.toLowerCase().trim() === 'true';
}

export function checkIsAllowedToUpdate() {
    const elem = document.getElementById('IsAllowedToUpdate') as HTMLInputElement;

    if (elem == null) return false;

    return elem.value.toLowerCase().trim() === 'true';
}

