import { AppException } from "./core";
import { insertHTMLInputValueToJSON, setHTMLInputValueWithJSON } from "./formUtilities";

const dataPrefixToStripAttributeName = 'data-prefix-to-strip';

const prefixlessRowIDInputName = 'rowID';

interface IModalFormObjectAndRowID<TModalFormObject> {
    modalFormObject: TModalFormObject,
    rowID: string | null
}

/**
 * Fill the form of a Boostrap modal using an object. If the object is null,
 * the form is emptied. If the object is empty, empty the form.
 * @param obj
 * @param modalElem
 */
function setModalForm(obj: any, modalElem: HTMLElement, rowID?: string | null | undefined) {
    forEachInputElementInModal(modalElem, (inputElem: HTMLInputElement | HTMLSelectElement, prefixlessName: string) => {
        if (checkIsRowIDInput(prefixlessName)) {
            if (rowID != null) {
                inputElem.value = rowID;
            } else {
                inputElem.value = '';
            }
        } else {
            setHTMLInputValueWithJSON(inputElem, obj, prefixlessName);
        }
    });
}

/**
 * Set the values of a form inside a modal using a JSON. Then show that modal.
 * @param obj
 * @param $modal
 */
export function setModalFormAndShow(obj: any, $modal: JQuery, rowID?: string | null | undefined) {
    if ($modal.length === 0) {
        throw new AppException("Could not set modal form and show because the given JQuery object contains no results.")
    } else if ($modal[0].className.indexOf('modal') === -1) {
        throw new AppException("Could not set modal form and show because the given JQuery object does not have the modal class.")
    }

    setModalForm(obj, $modal[0], rowID);
    $modal.modal('show');
}

export function createModalFormObject<T>(modalElem: HTMLElement, shouldIncludeLabels?: boolean | undefined) {
    return createModalFormObjectAndRowIDFromModal<T>(modalElem, shouldIncludeLabels).modalFormObject;
}

/**
 * Get a form element inside a modal and convert its content into a JSON.
 * @param modalElem
 */
export function createModalFormObjectAndRowIDFromModal<T>(modalElem: HTMLElement, shouldIncludeLabels?: boolean | undefined)
    : IModalFormObjectAndRowID<T>
{
    if (shouldIncludeLabels == null) {
        shouldIncludeLabels = false;
    }

    let rowID: string | null = null;
    const formObject: { [key: string]: any } = {};

    forEachInputElementInModal(modalElem, (inputElem: HTMLInputElement | HTMLSelectElement, prefixlessName: string) => {
        if (checkIsRowIDInput(prefixlessName)) {
            rowID = inputElem.value;
        } else {
            insertHTMLInputValueToJSON(inputElem, formObject, prefixlessName, shouldIncludeLabels);
        }
    });

    if (rowID != null) {
        rowID = (rowID as string).trim();
    }

    if (rowID == '') {
        rowID = null;
    }

    return {
        rowID: rowID,
        modalFormObject: formObject as T,
    };
}

/**
 * Iterate every input element in a modal, also providing its name without the
 * modal prefix.
 * @param modalElem
 * @param callbackfn
 */
function forEachInputElementInModal(
    modalElem: HTMLElement,
    callbackfn: (input: HTMLInputElement | HTMLSelectElement, prefixlessName: string) => void
) {
    const prefixToStrip = modalElem.getAttribute(dataPrefixToStripAttributeName);
    var regex = new RegExp(`^${prefixToStrip}`);

    function callCallbackWithPrefixlessName(el: HTMLInputElement | HTMLSelectElement, prefixToStrip: string | null) {
        let prefixlessName = el.name;

        if (prefixlessName == null || prefixlessName == '') {
            return;
        }

        if (prefixToStrip != null) {
            prefixlessName = prefixlessName.replace(regex, '');

            if (prefixlessName == null || prefixlessName == '') {
                return;
            }

            prefixlessName = prefixlessName[0].toLowerCase() + prefixlessName.substr(1);
        }

        callbackfn(el, prefixlessName);
    }

    modalElem.querySelectorAll('input').forEach((el: HTMLInputElement) => {
        if (el.type === 'submit') {
            return
        }
        callCallbackWithPrefixlessName(el, prefixToStrip);
    });

    modalElem.querySelectorAll('select').forEach((el: HTMLSelectElement) => {
        callCallbackWithPrefixlessName(el, prefixToStrip);
    });
}

/**
 * Check if an input's name indicates that it contains the id of the row the
 * modal is currently editing.
 * @param prefixlessName
 */
function checkIsRowIDInput(prefixlessName: string) {
    return prefixlessName != null && prefixlessName.toLowerCase() === prefixlessRowIDInputName.toLowerCase();
}
