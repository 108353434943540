// TypeScript rewrite of unobtrusive JQuery validation.

import $ from "jquery";
import "jquery-validation";

interface JQuery<TElement = HTMLElement> {
    validate: (a: any) => void;
}

interface IValidationInfo {
    options: { [key: string]: any },
    attachValidation: () => void
    validate: () => void
}

declare global {
    namespace JQueryValidation {
        interface ValidatorStatic {
            unobtrusive: UnobtrusiveApi;
        }
    }
}

const $jQval = $.validator;
const unobtrusiveValidationLabel = "unobtrusiveValidation";
const dataOnSubmitAttributeName = 'data-on-submit';

$jQval.addMethod("__dummy__", function (value: any, element: HTMLElement, params: any) {
    return true;
});

$jQval.addMethod("regex", function regexFn(this: any, value: string, element: HTMLElement, pattern: string | RegExp) {
    let match;

    if (this.optional(element)) {
        return true;
    }

    match = new RegExp(pattern).exec(value);
    return !!(match && (match.index === 0) && (match[0].length === value.length));
});

$jQval.addMethod("nonalphamin", function checkNonAlphaMin(value: string, element: HTMLElement, nonalphamin: number) {
    let match;
    if (nonalphamin) {
        match = value.match(/\W/g);
        match = match && match.length >= nonalphamin;
    }
    return !!match;
});

function setValidationValues(options: any, ruleName?: string, value?: (boolean | string[] | HTMLElement | { [key: string]: any })) {
    if (ruleName == null || value == null) {
        return;
    }

    options.rules[ruleName] = value;

    if (options.message) {
        options.messages[ruleName] = options.message;
    }
}

function splitAndTrim(value: string) {
    return value.replace(/^\s+|\s+$/g, "").split(/\s*,\s*/g);
}

function escapeAttributeValue(value: string) {
    // As mentioned on http://api.jquery.com/category/selectors/
    return value.replace(/([!"#$%&'()*+,./:;<=>?@\[\\\]^`{|}~])/g, "\\$1");
}

function getModelPrefix(fieldName: string) {
    return fieldName.substr(0, fieldName.lastIndexOf(".") + 1);
}

function appendModelPrefix(value: string, prefix: string) {
    if (value.indexOf("*.") === 0) {
        value = value.replace("*.", prefix);
    }
    return value;
}

function onError(this: any, error: any, inputElement: HTMLInputElement[]) {  // 'this' is the form element
    let container = $(this).find("[data-valmsg-for='" + escapeAttributeValue(inputElement[0].name) + "']"),
        replaceAttrValue = container.attr("data-valmsg-replace"),
        replace = replaceAttrValue ? $.parseJSON(replaceAttrValue) !== false : null;

    container.removeClass("field-validation-valid").addClass("field-validation-error");
    error.data("unobtrusiveContainer", container);

    if (replace) {
        container.empty();
        error.removeClass("input-validation-error").appendTo(container);
    }
    else {
        error.hide();
    }
}

function onErrors(this: HTMLFormElement, event: Event, validator: any) {  // 'this' is the form element
    let container = $(this).find("[data-valmsg-summary=true]"),
        list = container.find("ul");

    if (list && list.length && validator.errorList.length) {
        list.empty();
        container.addClass("validation-summary-errors").removeClass("validation-summary-valid");

        $.each(validator.errorList, function () {
            $("<li />").html(this.message).appendTo(list);
        });
    }
}

function onSuccess(error: any) {  // 'this' is the form element
    let container = error.data("unobtrusiveContainer");

    if (container) {
        let replaceAttrValue = container.attr("data-valmsg-replace"),
            replace = replaceAttrValue ? $.parseJSON(replaceAttrValue) : null;

        container.addClass("field-validation-valid").removeClass("field-validation-error");
        error.removeData("unobtrusiveContainer");

        if (replace) {
            container.empty();
        }
    }
}

function onReset(this: any, event: Event) {  // 'this' is the form element
    let $form = $(this),
        key = '__jquery_unobtrusive_validation_form_reset';
    if ($form.data(key)) {
        return;
    }
    // Set a flag that indicates we're currently resetting the form.
    $form.data(key, true);

    try {
        $form.data("validator").resetForm();
    } finally {
        $form.removeData(key);
    }

    $form.find(".validation-summary-errors")
        .addClass("validation-summary-valid")
        .removeClass("validation-summary-errors");

    $form.find(".field-validation-error")
        .addClass("field-validation-valid")
        .removeClass("field-validation-error")
        .removeData("unobtrusiveContainer")
        .find(">*")  // If we were using valmsg-replace, get the underlying error
        .removeData("unobtrusiveContainer");
}

/**
 * Parse a form element and return an object with 
 * - The information on how a form is to be validated.
 * - Functions to activate validation on that form.
 * @param form
 */
function getValidationInfo(form: HTMLFormElement): IValidationInfo {
    let $form = $(form) as any;

    let existingValidationInfo = $form.data(unobtrusiveValidationLabel);

    if (existingValidationInfo != null) {
        return existingValidationInfo;
    }

    let onResetProxy = $.proxy(onReset, form);
    let defaultOptions = $jQval.unobtrusive.options || {};

    let execInContext = function (name: string, args: any) {
        let func = defaultOptions[name];
        func && $.isFunction(func) && func.apply(form, args);
    };

    let validateOptions = {
        errorClass: defaultOptions.errorClass || "input-validation-error",
        errorElement: defaultOptions.errorElement || "span",
        errorPlacement: function () {
            onError.apply(form, <any>arguments);
            execInContext("errorPlacement", <any>arguments);
        },
        invalidHandler: function () {
            onErrors.apply(form, <any>arguments);
            execInContext("invalidHandler", <any>arguments);
        },
        submitHandler: function () {
            const dataOnSubmitAttribute = form.getAttributeNode(dataOnSubmitAttributeName);

            if (dataOnSubmitAttribute != null) {
                const submitFunctionName = dataOnSubmitAttribute.value;
                const submitFunction = (window as any)[submitFunctionName];

                if (submitFunction == null) {
                    console.error(`Found a '${dataOnSubmitAttributeName}' attribute that overrides default form submission but could not find function called '${submitFunctionName}'.`)
                    return;
                }

                submitFunction();
            } else {
                form.submit();
            }
        },
        messages: {},
        rules: {},
        success: function () {
            onSuccess.apply(form, <any>arguments);
            execInContext("success", arguments);
        }
    };

    let newValidationInfo = {
        options: validateOptions,
        attachValidation: function attachValidation() {
            $form
                .off("reset." + unobtrusiveValidationLabel, onResetProxy)
                .on("reset." + unobtrusiveValidationLabel, onResetProxy)
                .validate(validateOptions);
        },
        validate: function () {  // a validation function that is called by unobtrusive Ajax
            $form.validate();
            return $form.valid();
        }
    };

    $form.data(unobtrusiveValidationLabel, newValidationInfo);

    return newValidationInfo;
}


// Adapter initialization

interface IAdapter {
    name: string | null | undefined
    params: any
    adapt: (options: any) => void
}

/**
 * Stores an array of adapter for validation and also contains methods to add to that array.
 */
class AdapterContext {
    public adapters: IAdapter[];

    constructor() {
        this.adapters = [];
    }

    /**
     * Adds a new adapter to convert unobtrusive HTML into a jQuery Validate validation.
     * @param adapterName The name of the adapter to be added.This matches the name used in the data-val-nnnn HTML attribute (where nnnn is the adapter name).
     * @param params [Optional] An array of parameter names(strings) that will be extracted from the data-val-nnnn-mmmm HTML attributes (where nnnn is the adapter name, and mmmm is the parameter name).
     * @param fn The function to call, which adapts the values from the HTML attributes into jQuery Validate rules and/or messages.
     */
    add(adapterName?: string, params?: any, fn?: any) {
        if (!fn) {  // Called with no params, just a function
            fn = params;
            params = [];
        }
        this.adapters.push({ name: adapterName, params: params, adapt: fn });
        return this;
    };

    /**
     * Adds a new adapter to convert unobtrusive HTML into a jQuery Validate
     * validation, where the jQuery Validate validation rule has no parameter
     * values.
     * @param adapterName [Optional] The name of the jQuery Validate rule. If not provided, the value of adapterName will be used instead.
     * @param ruleName [Optional] The name of the jQuery Validate rule. If not provided, the value of adapterName will be used instead
     * @returns jQuery.validator.unobtrusive.adapters
     */
    addBool(adapterName?: string, ruleName?: string) {
        return this.add(adapterName, function (options: any) {
            setValidationValues(options, ruleName || adapterName, true);
        });
    };

    /**
    * Adds a new adapter to convert unobtrusive HTML into a jQuery Validate validation, where
    * the jQuery Validate validation has three potential rules (one for min-only, one for max-only, and
    * one for min-and-max). The HTML parameters are expected to be named -min and -max.
    * @param adapterName The name of the adapter to be added. This matches the name used in the data-val-nnnn HTML attribute (where nnnn is the adapter name).
    * @param minRuleName The name of the jQuery Validate rule to be used when you only have a minimum value.
    * @param maxRuleName The name of the jQuery Validate rule to be used when you only have a maximum value.
    * @param minMaxRuleName The name of the jQuery Validate rule to be used when you have both a minimum and maximum value.
    * @param minAttribute [Optional] The name of the HTML attribute that contains the minimum value. The default is "min".
    * @param maxAttribute [Optional] The name of the HTML attribute that contains the maximum value. The default is "max".
    */
    addMinMax(adapterName: string, minRuleName: string, maxRuleName?: string, minMaxRuleName?: string, minAttribute?: string, maxAttribute?: string) {
        return this.add(adapterName, [minAttribute || "min", maxAttribute || "max"], function (options: any) {
            let min = options.params.min,
                max = options.params.max;

            if (min && max) {
                setValidationValues(options, minMaxRuleName, [min, max]);
            }
            else if (min) {
                setValidationValues(options, minRuleName, min);
            }
            else if (max) {
                setValidationValues(options, maxRuleName, max);
            }
        });
    };
    /**
     * Adds a new adapter to convert unobtrusive HTML into a jQuery Validate validation, where the jQuery Validate validation rule has a single value.
     * @param adapterName The name of the adapter to be added. This matches the name used in the data-val-nnnn HTML attribute(where nnnn is the adapter name).
     * @param attribute [Optional] The name of the HTML attribute that contains the value. The default is "val".
     * @param ruleName [Optional] The name of the jQuery Validate rule. If not provided, the value of adapterName will be used instead.
     */
    addSingleVal(adapterName: string, attribute?: string, ruleName?: string) {
        if (attribute == null) {
            attribute = "val";
        }
        if (ruleName == null) {
            ruleName = attribute;
        }

        return this.add(adapterName, [attribute], function (options: any) {
            setValidationValues(options, ruleName || adapterName, options.params[attribute!]);
        });
    };
}

class UnobtrusiveApi {
    adapterContext: AdapterContext
    options: { [key: string]: any }

    constructor(adapterContext: AdapterContext) {
        this.adapterContext = adapterContext;
        this.options = {};
    }

    /**
     * Parses all the HTML elements in the specified selector. It looks for input
     * elements decorated with the [data-val=true] attribute value and enables
     * validation according to the data-val-* attribute values.
     * @param selector
     */
    parse(selector: any) {
        let $selector = $(selector);

        let $forms = $selector.parents()
            .addBack()
            .filter("form")
            .add($selector.find("form"))
            .has("[data-val=true]");

        if ($forms.length !== 0) {
            const $dataValTrueElements = $selector.find("[data-val=true]");

            $dataValTrueElements.each(function (this: HTMLElement) {
                $jQval.unobtrusive.parseElement(this as HTMLInputElement, true);
            });

            $forms.each(function () {
                let validationInfo = getValidationInfo(this);

                if (validationInfo) {
                    validationInfo.attachValidation();
                }
            });
        }
    }

    /**
     * Parses a single HTML element for unobtrusive validation attributes.
     * @param element  The elmeent to be parsed.
     * @param skipAttach Parsing just this single element, you should specify true.
     * If parsing several elements, you should specify false, and manually attach
     * the validation to the form when you are finished. The default is false.
     */
    parseElement(element: HTMLInputElement, skipAttach: boolean) {
        const $element = $(element);
        const form = <HTMLFormElement><any>($element.parents("form")[0]);
        let valInfo: any;
        let rules: any;
        let messages: any;

        if (!form) {  // Cannot do client-side validation without a form
            return;
        }

        valInfo = getValidationInfo(form);
        valInfo.options.rules[element.name] = rules = {};
        valInfo.options.messages[element.name] = messages = {};

        $.each(this.adapterContext.adapters, function (this: IAdapter) {
            let prefix = "data-val-" + this.name,
                message = $element.attr(prefix),
                paramValues: any = {};

            if (message !== undefined) {  // Compare against undefined, because an empty message is legal (and falsy)
                prefix += "-";

                $.each(this.params, function () {
                    paramValues[this] = $element.attr(prefix + this);
                });

                this.adapt({
                    element: element,
                    form: form,
                    message: message,
                    params: paramValues,
                    rules: rules,
                    messages: messages
                });
            }
        });

        $.extend(rules, { "__dummy__": true });

        if (!skipAttach) {
            valInfo.attachValidation();
        }
    }
}


// Create adapter context and validation API.

const adapterContext = new AdapterContext();

if ($jQval.methods.extension) {
    adapterContext.addSingleVal("accept", "mimtype");
    adapterContext.addSingleVal("extension", "extension");
} else {
    // for backward compatibility, when the 'extension' validation method does not exist, such as with versions
    // of JQuery Validation plugin prior to 1.10, we should use the 'accept' method for
    // validating the extension, and ignore mime-type validations as they are not supported.
    adapterContext.addSingleVal("extension", "extension", "accept");
}

adapterContext.addSingleVal("regex", "pattern");
adapterContext.addBool("creditcard")
    .addBool("date")
    .addBool("digits")
    .addBool("email")
    .addBool("number")
    .addBool("url");

adapterContext
    .addMinMax("length", "minlength", "maxlength", "rangelength")
    .addMinMax("range", "min", "max", "range");

adapterContext
    .addMinMax("minlength", "minlength")
    .addMinMax("maxlength", "minlength", "maxlength");

adapterContext.add("equalto", ["other"], function (options: any) {
    let prefix = getModelPrefix(options.element.name),
        other = options.params.other,
        fullOtherName = appendModelPrefix(other, prefix),
        element = $(options.form).find(":input").filter("[name='" + escapeAttributeValue(fullOtherName) + "']")[0];

    setValidationValues(options, "equalTo", element);
});

adapterContext.add("required", function (options: any) {
    // jQuery Validate equates "required" with "mandatory" for checkbox elements
    if (options.element.tagName.toUpperCase() !== "INPUT" || options.element.type.toUpperCase() !== "CHECKBOX") {
        setValidationValues(options, "required", true);
    }
});

adapterContext.add("remote", ["url", "type", "additionalfields"], function (options: any) {
    let data: { [key: string]: () => string | number | string[] | undefined } = {};

    let value = {
        url: options.params.url,
        type: options.params.type || "GET",
        data: data
    };

    const prefix = getModelPrefix(options.element.name);

    $.each(splitAndTrim(options.params.additionalfields || options.element.name), function (i, fieldName) {
        let paramName = appendModelPrefix(fieldName, prefix);
        value.data[paramName] = function () {
            let field = $(options.form).find(":input").filter("[name='" + escapeAttributeValue(paramName) + "']");
            // For checkboxes and radio buttons, only pick up values from checked fields.
            if (field.is(":checkbox")) {
                return field.filter(":checked").val() || field.filter(":hidden").val() || '';
            }
            else if (field.is(":radio")) {
                return field.filter(":checked").val() || '';
            }
            return field.val();
        };
    });

    setValidationValues(options, "remote", value);
});

adapterContext.add("password", ["min", "nonalphamin", "regex"], function (options: any) {
    if (options.params.min) {
        setValidationValues(options, "minlength", options.params.min);
    }
    if (options.params.nonalphamin) {
        setValidationValues(options, "nonalphamin", options.params.nonalphamin);
    }
    if (options.params.regex) {
        setValidationValues(options, "regex", options.params.regex);
    }
});

adapterContext.add("fileextensions", ["extensions"], function (options: any) {
    setValidationValues(options, "extension", options.params.extensions);
});

$jQval.unobtrusive = new UnobtrusiveApi(adapterContext);

export function activateUnobtrusiveValidation() {
    $(function () {
        $jQval.unobtrusive.parse(document);
    });
}
